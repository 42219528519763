@import "_variables.scss";

@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');

* {
    box-sizing: border-box;
}

// base stuff that can be migrated to core
.uk-form-horizontal.uk-form-horizontal--tight {
    .uk-form-label {
        width: calc(var(--global-margin) * 5);
    }
    .uk-form-controls {
        margin-left: calc(var(--global-margin) * 5.5);
    }
}

.radios-horizontal .input-inner-wrapper {
    display: flex;

    > * {
        margin-inline-end: calc(var(--global-margin) * 0.5);
        min-width: calc(var(--global-margin) * 4);
    }
}

// flex gap support
.uk-flex-gap-s, .uk-flex-gap-small,
.uk-gap-s, .uk-gap-small {
    gap:  $global-small-margin;
}

.uk-flex-gap-m, .uk-flex-gap-medium ,.uk-flex-gap,
.uk-gap-m, .uk-gap-medium ,.uk-gap {
    gap:  $global-margin;
}

.uk-flex-gap-l, .uk-flex-gap-large,
.uk-gap-l, .uk-gap-large {
    gap:  $global-large-margin;
}

// flex break support
@mixin flex-break-small {
    @media (max-width: $breakpoint-small) {
        flex-wrap: wrap;


        & > * {
            margin-bottom:  calc(var(--global-margin) * 0.5);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@mixin flex-break-medium {
    @media (max-width: $breakpoint-medium) {
        flex-wrap: wrap;

        & > * {
            margin-bottom:  calc(var(--global-margin) * 0.5);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@mixin flex-break-large {
    @media (max-width: $breakpoint-large) {
        flex-wrap: wrap;

        & > * {
            margin-bottom:  calc(var(--global-margin) * 0.5);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@mixin flex-break-xlarge {
    @media (max-width: $breakpoint-xlarge) {
        flex-wrap: wrap;

        & > * {
            margin-bottom:  calc(var(--global-margin) * 0.5);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.flex-break-small-start {
    @include flex-break-small;
    @media (max-width: $breakpoint-small) {
        justify-content: flex-start;
    }
}

.flex-break-small-center {
    @include flex-break-small;
    @media (max-width: $breakpoint-small) {
        justify-content: center;
        text-align: center;
    }
}

.flex-break-medium-end {
    @include flex-break-medium;
    @media (max-width: $breakpoint-medium) {
        justify-content: flex-end;
    }
}

.flex-break-medium-start {
    @include flex-break-medium;
    @media (max-width: $breakpoint-medium) {
        justify-content: flex-start;
    }
}

.flex-break-medium-center {
    @include flex-break-medium;
    @media (max-width: $breakpoint-medium) {
        justify-content: center;
        text-align: center;
    }
}

.flex-break-medium-end {
    @include flex-break-medium;
    @media (max-width: $breakpoint-medium) {
        justify-content: flex-end;
    }
}

.flex-break-large-start {
    @include flex-break-large;
    @media (max-width: $breakpoint-large) {
        justify-content: flex-start;
    }
}

.flex-break-large-center {
    @include flex-break-large;
    @media (max-width: $breakpoint-large) {
        justify-content: center;
        text-align: center;
    }
}

.flex-break-large-end {
    @include flex-break-xlarge;
    @media (max-width: $breakpoint-large) {
        justify-content: flex-end;
    }
}

.flex-break-xlarge-start {
    @include flex-break-xlarge;
    @media (max-width: $breakpoint-xlarge) {
        justify-content: flex-start;
    }
}

.flex-break-xlarge-center {
    @include flex-break-xlarge;
    @media (max-width: $breakpoint-xlarge) {
        justify-content: center;
        text-align: center;
    }
}

.flex-break-xlarge-end {
    @include flex-break-xlarge;
    @media (max-width: $breakpoint-xlarge) {
        justify-content: flex-end;
    }
}

.uk-flex-stretch {
    align-items: stretch;
}

.ui-blocker {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 20;
    background: var(--global-background);
    opacity: 0.5;
    cursor: not-allowed;
}

.uk-background-success {
    background-color: var(--global-success-background)
}

.uk-background-danger {
    background-color: var(--global-danger-background)
}


.uk-success-style {
    background-color: var(--global-primary-success-background);
    color: var(--global-inverse-color);
}

.uk-text-inverse-color {
    color: var(--global-inverse-color);
}

.uk-background-inverse {
    background-color: var(--global-inverse-color);;
}

.uk-events-none {
    pointer-events: none;
}

.uk-text-underline {
    text-decoration: underline;
}

.uk-text-semibold {
    font-weight: 500;
    font-weight: 600;
}

.uk-border-radius {
    border-radius: var(--border-radius-large);
}

.uk-border-radius-small {
    border-radius: var(--border-radius-small);
}

.round-bottom {
    border-radius: 0 0 var(--border-radius-small) var(--border-radius-small);
}

.round-bottom-large {
    border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
}

.round-bottom-top {
    border-radius: var(--border-radius-small) var(--border-radius-small)  0 0 ;
}

.round-bottom-top-large {
    border-radius: var(--border-radius-large) var(--border-radius-large)  0 0;
}


// end base stuff


// specifics for billrun portal
.decorated-title {
    position: relative;
    display: flex;
    flex-wrap: wrap;



    .title-link {
        font-size: calc(var(--global-font-size) * 1.1);
        margin-inline-start: auto;

    }

    &::after {
        content: ' ';
        position: absolute;
        top: 100%;
        left: 0;
        width: 50%;
        max-width: 115px;
        height: 2px;
        background-image: linear-gradient(to right, $global-primary-background 50%, transparent 100%);
    }

    &.decorated-title-center {
        text-align: center;

        .sub-title {
            position: relative;
            top: -4px;
            margin: 0;
            font-size: calc(var(--global-font-size) * 1);
        }
        &::after {
            content: ' ';
            position: absolute;
            bottom: 3px;

            left: 0;
            width: 100%;
            max-width: 100%;
            height: 2px;
            background-image: linear-gradient(to right, transparent 16%, $global-primary-background 27%, $global-primary-background 73%, transparent 84%, transparent 100%);
        }

    }



}

.uk-notification-top-center {
    margin-top: 80px;
}

// notification accesibility fix
.uk-notification-message.uk-notification-message-danger {
    background: $global-danger-background;
    color: $global-color;
}
// end specifics for billrun portal



// specifics for the selected color variables
.uk-text-inverse {
    color: var(--global-inverse-color);
}


.uk-form-horizontal.uk-form-horizontal--tight {
    .uk-form-label {
        width: calc(var(--global-margin) * 5);
    }
    .uk-form-controls {
        margin-left: calc(var(--global-margin) * 5.5);
    }
}

.radios-horizontal .input-inner-wrapper {
    display: flex;

    > * {
        margin-inline-end: calc(var(--global-margin) * 0.5);
        min-width: calc(var(--global-margin) * 4);
    }
}

// talron typography aux
.tr-font-xl {
    font-size :var(--font-xl);
}

.tr-font-l {
    font-size :var(--font-l);
}

.tr-font-m {
    font-size :var(--font-m);
}

.tr-font-s {
    font-size :var(--font-s);
}

.tr-font-xs {
    font-size :var(--font-xs);
}

.tr-font-title {
    font-weight: bold;
    font-size: var(--font-l);
}

.tr-font-semibold {
    font-weight: 500;
}

.tr-font-bold {
    font-weight: 700;
}
.tr-font-bolder {
    font-weight: 800;
}


.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
