

//define all "hardcoded" framework variables as css variables,
// NOTICE: due to color interpolation, some vars MUST still be SCSS vars
:root, #app, html {
    --global-font-size: 16px;
    --global-margin: 20px;

    --global-inverse-color: #fff;
    --global-color: #000000;

    --global-muted-background: #f8f8f8;
    --global-primary-background: #0284bb;
    --global-primary-background-dark: #004d82;
    --global-primary-background-darkest: #00376e;
    --global-success-background: #006000;
    --global-success-background-light: #effff0;

    --global-warning-background-light: #f2c94c;
    --global-warning-background: #f2994a;
    --global-danger-background: #ffd6d6;
    --global-muted-color: #999;

    --modal-error-color: #C11300;
    --modal-error-color-light: #fbbeb5;

    --global-background: #fff;
    --global-secondary-background: #1d1d1d;
    --global-link-color: #004d82;
    --global-link-hover-color: #005B80;
    --base-ins-background: #ffd;
    --base-mark-background: #ffd;
    --global-border: #e5e5e5;

    --base-selection-background: #004d82;
    --lightbox-background: #000;
    --lightbox-item-color: rgba(255, 255, 255, 0.7);

    --lightbox-toolbar-background: rgba(0, 0, 0, 0.3);
    --lightbox-toolbar-color: rgba(255, 255, 255, 0.7);

    --lightbox-toolbar-icon-color: rgba(255, 255, 255, 0.7);
    --lightbox-toolbar-icon-hover-color: #fff;
    --lightbox-button-color: rgba(255, 255, 255, 0.7);
    --lightbox-button-hover-color: #fff;
    --modal-background: rgba(0, 0, 0, 0.6);
    --table-row-active-background: #ffd;

    --tooltip-background: #666;
    --box-shadow-bottom-background: #444;

    --dragover-box-shadow: 0 0 20px rgba(100, 100, 100, 0.3);

    --global-small-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    --global-medium-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    --global-large-box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
    --global-xlarge-box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
    --dropdown-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
    --navbar-dropdown-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
    --navbar-dropbar-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.05);

    --global-medium-gray: #ddd;
    --accent-color-1: #021652;
    --accent-color-2: #eb9700;
    --accent-color-3: #0098d2;
    --facebook-color: #3b5998;

    --global-success-color: #4A7C03;
    --global-warning-color: #eb9700;
    --global-danger-color: #CA1218;
    --global-small-gutter: 15px;
    --border-radius-small : 8px;
    --border-radius-medium: 16px;
    --border-radius-large: 25px;

    --talron-separator-color: #fcba38;
    // talron aux font sizes
    --font-xl : 24px;
    --font-l  : 20px;
    --font-m  : 14px;
    --font-s  : 12px;
    --font-xs : 11px;

    @media screen and (min-width: 768px) {
        --font-xl : 36px;
        --font-l  : 30px;
        --font-m  : 15px;
        --font-s  : 14px;
        --font-xs : 12px;
    }

}


//now, set the framework SCSS vars, to use the css variables. this way, app can implement themes!
$global-inverse-color: #fff;
$global-color: #3a3a3a;
$global-muted-background: #f8f8f8;
$global-primary-background: #0284bb;
$global-success-background: #006000;
$global-success-background-light: #effff0;
$global-warning-background: #f2994a;
$global-danger-background: #ffd6d6;
$global-muted-color: #999;
$global-background: #fff;
$global-danger-color: #ec1e26;
$global-secondary-background: #1d1d1d;
$global-border: #e5e5e5;
$global-margin: 20px;

$global-link-color: var(--global-link-color);
$global-link-hover-color: var(--global-link-hover-color);
$base-ins-background: var(--base-ins-background);
$base-mark-background: var(----base-mark-background);

$base-selection-background: var(--base-selection-background);
$lightbox-background: var(--lightbox-background);
$lightbox-item-color: var(--lightbox-item-color);
$lightbox-toolbar-background: var(--lightbox-toolbar-background);
$lightbox-toolbar-color: var(--lightbox-toolbar-color);
$lightbox-toolbar-icon-color: var(--lightbox-toolbar-icon-color);
$lightbox-toolbar-icon-hover-color: var(--lightbox-toolbar-icon-hover-color);
$lightbox-button-color: var(--lightbox-button-color);
$lightbox-button-hover-color: var(--lightbox-button-hover-color);
$modal-background: var(--modal-background);
$table-row-active-background: var(--table-row-active-background);
$tooltip-background: var(--tooltip-background);
$box-shadow-bottom-background: var(--box-shadow-bottom-background);
$dragover-box-shadow: var(--dragover-box-shadow);
$global-small-box-shadow: var(--global-small-box-shadow);
$global-medium-box-shadow: var(--global-medium-box-shadow);
$global-large-box-shadow: var(--global-large-box-shadow);
$global-xlarge-box-shadow: var(--global-xlarge-box-shadow);
$dropdown-box-shadow: var(--dropdown-box-shadow);
$navbar-dropdown-box-shadow: var(--navbar-dropdown-box-shadow);
$navbar-dropbar-box-shadow: var(--navbar-dropbar-box-shadow);


$global-font-family: 'Assistant', sans-serif!default;

$form-placeholder-color : var(--global-color);


$table-header-cell-color: var(--global-color);
@import "~uikit/src/scss/variables-theme.scss";
